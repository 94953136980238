import('../vue-shared').then(({default: vue}) => {
  $(() => {
    const mountPoint = document.getElementById('hardware-key-auth');
    if (!mountPoint) {
      return;
    }

    const initData = mountPoint.getAttribute('data-notifications');
    vue.prototype.$systemNotifications = JSON.parse(initData ? initData : '{}');
    vue.prototype.$systemNotificationUserId = mountPoint.getAttribute('data-user-id') ?? 0;

    import('./HardwareKeyAuth.vue').then(({default: component}) => {
      new vue({
        render: (h) => h(component),
        el: mountPoint,
      }).$mount();
    });
  });
});
